import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { CodeBlock } from "../commonComponents"


export default function SentimentAPIDocs() {
  const no_answer_json = {
  }

  const json = {
    "sentiment": -40
  }

  return (
    <Layout
      keywords="Sentiment API guide"
      title="Use IrisAgent's API to fetch sentiment analysis of support conversations"
      description="Documentation on how to use IrisAgent's API to fetch AI-powered sentiment for support conversations"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/sentiment-api-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Sentiment API Guide</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>Sentiment API</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>
                    <h1>Sentiment API Documentation</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    IrisAgent's <a href="../ai-sentiment-analysis">AI Sentiment Analysis</a> can be used an API to get AI-powered sentiment corresponding to any conversation. The value of sentiment score varies between -100 and 100. A score of -100 indicates a very negative sentiment, 0 indicates a neutral sentiment, and 100 indicates a very positive sentiment.
                    <br/>
                    <br/>
                    <h2>
                    API Guide
                    </h2>
                    <br/>
                    Below is a <span style={{"font-family": "Consolas"}}>curl</span> command to call our API:
                    <CodeBlock language="javascript" value={`
curl -X POST --location "https://api1.irisagent.com/v1/sentiment" \\
    -H "Content-Type: application/json" \\
    -H "Authorization: Bearer Replace_me_business_id" \\
    -H "X-Language-Iso-Code: en" \\
    -d '{
          "conversationId": "12345",
          "message": "Customer: Application will not access Flight Log\nWhen clicking on \"Flight Log\" the application keeps producing an ERROR message and will not allow access or Flight Log updating. Please help to fix this ASAP.\n------------------\n\nAgent: Thank you for the information. I have forwarded this issue with all the details to our engineering team for further investigation. I will let you know as soon as any new information becomes available.\nJohn Doe | Technical Support Specialist O: _ • TF: _\nAcme Systems International, Inc. \n\nAgent: Billy - Please let the customer know that this issue has been fixed.\n\n\nAgent: I've updated and closed this issue as resolved. While I'm confident we've addressed your issues, if you have any other questions, comments or concerns feel free to create a new ticket. I'm here to help.\n\"Please do NOT reply to this email unless you would like us to reopen it. Thank you\"\nJohn Doe | Technical Support Specialist O: _ • TF: _\nAcme Systems International, Inc."
        }'
          `}>
</CodeBlock>
<br/>
The API inputs are as follows,
<br/><br/>
<table style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
    <tr>
        <th>Field</th>
        <th>Description</th>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>conversationId</span></td>
        <td>[Required] Unique ticket or chat id</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>message</span></td>
        <td>[Required] Support conversation that needs AI-powered sentiment analysis.</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>Replace_me_business_id</span></td>
        <td>This is the ID provided by the IrisAgent team</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>X-Language-Iso-Code</span></td>
        <td>[Optional] This optional header allows you to specify the desired language for IrisGPT's responses. If not provided, automatic language detection will be used</td>
    </tr>
</table>

<br/><br/>
A sample output of the API:
<br/><br/>
<CodeBlock language="json" value={JSON.stringify(json, null, 2)}>
</CodeBlock>

<br/><br/>
A sample output of the API when no sentiment was generated:
<br/><br/>
<CodeBlock language="json" value={JSON.stringify(no_answer_json, null, 2)}>
</CodeBlock>

                    </div>
                    </div>
                    </section>

</Layout>
  )
}